

import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import { dateConvert } from "@/core/filters/datatime"
import {Field} from "vee-validate";
import {commonf} from "@/core/filters/common";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import ReservationPreview from "@/components/reservations/ReservationPreview.vue";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import reservationConfirmTypes from "@/core/data/reservationConfirmType";
import HotelVoucherModal from "@/components/general-modals/vouchers/HotelVoucherModal2.vue";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface srcItem {
  srcFromDate: string;
  srcToDate: string;
  srcStartDate1: string;
  srcStartDate2: string;
  srcFinishDate1: string;
  srcFinishDate2: string;
  srcAccount: number;
  srcDepartment: number,
  srcMarket: number;
  srcPNR: string;
  srcVoucherNo: string;
  srcAccountVoucherNo: string;
  srcName: string;
  srcLastName: string;
}

interface voucherItem {
  Description: string;
  ManuelEmail: string;
  OperationEmail: string;
}

export default defineComponent({
  name: "hotel-list",
  components: {
    Field,
    ReservationPreview,
    HotelVoucherModal,
  },
  data: function () {
    return {
      imgUrl: '',
      searchName: '',
      sortField: '',
      sortType: '',
      filterField: ['Title'],
      filterFieldName: ['İsim'],
    }
  },

  props: {
    widgetClasses: String,
  },
  methods: {
    ...dateConvert.method,

    sortData(attr, data) {
      if (this.sortField == '') {
        this.sortType = "asc";
      } else {
        this.sortType = this.sortField == attr && this.sortType == 'asc' ? "desc" : "asc";
      }
      this.sortField = attr;

      if (this.sortType == 'asc' || this.sortType == 'desc') {
        this[data].sort((a, b) => {
          let fa = '';
          let fb = '';
          if (typeof (attr) == 'string') {
            if (this.sortType == 'asc') {
              fa = a[attr].toLowerCase();
              fb = b[attr].toLowerCase();
            } else {
              fb = a[attr].toLowerCase();
              fa = b[attr].toLowerCase();
            }
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else {
            return this.sortType == 'asc' ? (a.Mobile - b.Mobile) : (b.Mobile - a.Mobile);
          }
        });
      }
    }
  },


  setup() {

    const srcItem = ref<srcItem>({
      srcFromDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcToDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcStartDate1: "",
      srcStartDate2: "",
      srcFinishDate1: "",
      srcFinishDate2: "",
      srcAccount: 0,
      srcDepartment: 0,
      srcMarket: 0,
      srcPNR: "",
      srcVoucherNo: "",
      srcAccountVoucherNo: "",
      srcName: "",
      srcLastName: "",
    });

    const voucherItem = ref<voucherItem>({
      Description: "",
      ManuelEmail: "",
      OperationEmail: "operasyon@cyprusroyal.com",
    });

    const refHotelVoucherModal = ref(null);

    const arrHotelList = ref([]);
    const arrReservationProductConfirmList = ref([]);
    const arrCheckedProducts = ref([]);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    function showVoucherEmail(prmHotelID) {
      (document.getElementById("divEmailVoucherOption"+prmHotelID) as HTMLElement).style.setProperty("display", "block", "important");
      (document.getElementById("divConfirmButtonSection"+prmHotelID) as HTMLElement).style.setProperty("display", "none", "important");

    }

    function hideVoucherEmail(prmHotelID) {
      (document.getElementById("divEmailVoucherOption"+prmHotelID) as HTMLElement).style.setProperty("display", "none", "important");
      (document.getElementById("divConfirmButtonSection"+prmHotelID) as HTMLElement).style.setProperty("display", "block", "important");
    }

    function getIsChecked(prmID) {
      const inpEl = document.getElementById(prmID) as HTMLInputElement;
      if(inpEl.checked){
        return "true";
      }
      return "false";
    }

    function getCountByStatus(prmArr, prmHotelID, prmStatus) {
      let rv = 0;
      for (let i=0; i<prmArr.length; i++ ){
        let each = prmArr[i];
        //prmStatus = each.RemarksEnum1;
        //prmHotelID= each.RemarksINT1;
        //console.log("confirmreservation getCountByStatus " + prmHotelID  + " " +  prmStatus )
        if(prmStatus===each.RemarksEnum1 && prmHotelID===each.RemarksINT1){
          // console.log("confirmreservation getCountByStatus v1" )
          rv++;
        }

      }
      return rv;
    }


    function manuelVocuherConfirm(prmHotelID){
      //console.log("Confirm List manuelVocuherConfirm ID " + prmID);
      let vvClassName = "inputHotelProductConfirm" + prmHotelID;
      const arrElements = document.getElementsByClassName(vvClassName);
      let arrIds = [];
      for(let i=0; i<arrElements.length; i++){
        let eachElemnt = arrElements[i] as HTMLInputElement;

        if (eachElemnt.checked) {
          arrCheckedProducts.value.push(getProductByProductID(eachElemnt.getAttribute("productID")));
          arrIds.push(eachElemnt.getAttribute("productID"));
        }
      }

      store.dispatch(Actions.EXPORT_TO_PDF_SET_EMAIL_STATUS, {
        Ids:arrIds,
        Action: "CONFIRM",

      }).then((prmData) => {
        click_Search();
        libTools.method.swalSuccess("Tamam", "Seçili Voucher başarlılı bir şekilde onaylandı");
      })
    }



    function clickConfirm(prmEmailKind, prmHotelID){

      let vvClassName = "inputHotelProductConfirm" + prmHotelID;
      arrCheckedProducts.value=[];
      const arrElements = document.getElementsByClassName(vvClassName);

      let arrIds = [];
      let arrEmails = [];

      for(let i=0; i<arrElements.length; i++){
        let eachElemnt = arrElements[i] as HTMLInputElement;

        if (eachElemnt.checked) {
          arrCheckedProducts.value.push(getProductByProductID(eachElemnt.getAttribute("productID")));
          arrIds.push(eachElemnt.getAttribute("productID"));
          //arrEmails = addResEmail(arrEmails, eachElemnt.getAttribute("reservationEmail"));
        }
        console.log("eachElemnt " + eachElemnt.getAttribute("id") + " " + eachElemnt.checked + " " + eachElemnt.getAttribute("productID"));
      }


      let vvOperationMail = "inputSelectOperationEmail" + prmHotelID;
      const operationElements = document.getElementById(vvOperationMail) as HTMLInputElement;

      if(operationElements.checked){
        arrEmails.push(voucherItem.value.OperationEmail);
      }

      let vvID = "inputSelectManuelEmail" + prmHotelID;
      const manuelElements = document.getElementById(vvID) as HTMLInputElement;

      if(manuelElements.checked){
        arrEmails.push(voucherItem.value.ManuelEmail);
      }

      let vvClassName2 = "inputSelectReservationEmail" + prmHotelID;
      const arrElements2 = document.getElementsByClassName(vvClassName2);

      for(let i=0; i<arrElements2.length; i++){
        let eachElemnt = arrElements2[i] as HTMLInputElement;
        if (eachElemnt.checked) {
          arrEmails = addResEmail(arrEmails, eachElemnt.getAttribute("reservationEmail"));
        }
        //console.log("eachElemnt " + eachElemnt.getAttribute("id") + " " + eachElemnt.checked + " " + eachElemnt.getAttribute("productID"));
      }

      if(prmEmailKind==="CONFIRM") {
        document.getElementById("btnSendEmailActionCONFIRM_"+prmHotelID).setAttribute("data-kt-indicator", "on");
      }
      if(prmEmailKind==="CHANGE") {
        document.getElementById("btnSendEmailActionCHANGE_"+prmHotelID).setAttribute("data-kt-indicator", "on");
      }
      if(prmEmailKind==="CANCEL") {
        document.getElementById("btnSendEmailActionCANCEL_"+prmHotelID).setAttribute("data-kt-indicator", "on");
      }
      if(arrIds.length>0){

        if(arrEmails.length>0) {
          store.dispatch(Actions.EXPORT_TO_PDF_AND_SEND_EMAIL, {
            TemplateCode_Email: "HotelVoucher_Email",
            TemplateCode_Pdf: "HotelVoucher_Pdf",
            EmailKind: prmEmailKind,
            Description: voucherItem.value.Description,
            IsShowAccount: getIsChecked("inputShowHideAccountInfo" + prmHotelID),
            IsShowPayment: getIsChecked("inputShowHidePriceInfo" + prmHotelID),
            Ids: arrIds,
            Emails: arrEmails,
            //testEmails: ["hasanporklu@gmail.com"] ,
          }).then((prmData) => {
            //store.dispatch(Actions.EXPORT_TO_PDF_AND_SEND_EMAIL, {TemplateCode_Email: "HotelVoucher_Email", TemplateCode_Pdf: "HotelVoucher_Pdf", Ids: arrIds, Emails: arrEmails }).then((prmData) => {

            if (prmData.data !== undefined) {
              console.log(prmData.data);
              let isErrorExist = false;
              let vvSendEmail = "";

              for (let i = 0; i < prmData.data.length; i++) {
                let vvEach = prmData.data[i];
                console.log(vvEach);
                console.log(vvEach.result);
                if (vvEach.result !== "success") {
                  isErrorExist = true;
                } else {
                  vvSendEmail = vvEach.email;
                }
              }

              let vvText = "Tebrikler. " + vvSendEmail + " adresine başarılı bir şekilde " + prmData.data.length + " adet eposta gönderildi";

              if (!isErrorExist) {
                Swal.fire({
                  text: vvText, confirmButtonText: "Geri Dön!",
                  icon: "success", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                }).then(function () {
                  location.reload();
                });
              } else {
                libTools.method.swalError("Tamam", "Bazı Email bilgileri gönderilemedi");
              }

              //dataIndicatorLoadinStop(prmHotelID, prmEmailKind);

            }
          }).catch((prmData) => {
            libTools.method.swalError("Tamam", "Hata Oluştu");
          });
        }else{
          libTools.method.swalError("Tamam", "Email seçiniz.");
          dataIndicatorLoadinStop(prmHotelID, prmEmailKind);
        }
      }else{
        libTools.method.swalError("Tamam", "Reservasyon seçiniz.");
        dataIndicatorLoadinStop(prmHotelID, prmEmailKind);
      }

      //operasyon@cyprusroyal.com



      //console.log("clickConfirm " + arrElements.length);
      //console.log(arrCheckedProducts.value);
    }

    function dataIndicatorLoadinStop(prmHotelID, prmEmailKind) {
      if (prmEmailKind === "CONFIRM") {
        document.getElementById("btnSendEmailActionCONFIRM_" + prmHotelID).removeAttribute("data-kt-indicator");
      }
      if (prmEmailKind === "CHANGE") {
        document.getElementById("btnSendEmailActionCHANGE_" + prmHotelID).removeAttribute("data-kt-indicator");
      }
      if (prmEmailKind === "CANCEL") {
        document.getElementById("btnSendEmailActionCANCEL_" + prmHotelID).removeAttribute("data-kt-indicator");
      }
    }

    function addResEmail(prmArray, prmResEmail) {
      let isFounded = false;
      for(let i=0; i<prmArray.length; i++) {
        let each = prmArray[i];
        if (each==prmResEmail) {
          isFounded = true;
        }
      }
      if (!isFounded) {
        prmArray.push(prmResEmail);
      }
      return prmArray;
    }

    function getProductByProductID(prmProductID) {
      for (let i=0; i<arrReservationProductConfirmList.value.length; i++){
        let eachProduct = arrReservationProductConfirmList.value[i];
        if (eachProduct.ID === prmProductID){
          return  eachProduct;
        }
      }
      return null;
    }

    function click_Open_VoucherHotel(prmProduct) {
      //sendPdfToEmail(prmProduct)
    }

    function getHotelByID(prmHotelID) {
      for (let i=0; i<arrHotelList.value.length; i++){
        let each = arrHotelList.value[i];
        if(each.HotelID === prmHotelID){

          return each;
        }
      }

      return null;
    };

    function fillHotelList (prmData) {
      if (prmData !== undefined){
        for (let i=0; i<prmData.length; i++){
          let each = prmData[i];
          let eachHotel = getHotelByID(each.RemarksINT1);

          if (eachHotel==null){
            arrHotelList.value.push({
              HotelID: each.RemarksINT1,
              HotelTitle: each.RemarksVC2,
              ReservationEmail: each.ReservationEmail,
            })
          }
        }
      }
    };

    function getReservationProductsByHotelID (prmHotelID){
      const confirmResults = arrReservationProductConfirmList.value.filter(obj =>{
        return obj.RemarksINT1 === prmHotelID + "";
      })
      return confirmResults;
    };



    onMounted(()=>{
      setDatePicker();
      store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
      store.dispatch(Actions.MARKETS_LIST_CACHE, {});

      click_Search();
    });


    const myParams = computed(() => {
      return store.getters.generalParams;
    });

    const myDepartments = computed(() => {
      return store.getters.DepartmentsList;
    });

    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });

    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    const myAccountsTitle = computed(() => {
      let tmpArray = [];
      if (myAccountList.value.data !== undefined) {
        for (var i of myAccountList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });

    const getAccounts = () => {
      let myAccounts = document.getElementById("srcAccounts") as HTMLInputElement;
      let myPayload = {};
      if (myAccounts.value.length == 3) {
        myPayload = {
          'Title': myAccounts.value
        }
        store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
      }
    }
    const filterAccounts = (val) => {
      if (myAccountList.value.data != null) {
        return (myAccountList.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return true;
      }
    };

    var fpickStartDate1;
    var fpickStartDate2;
    var fpickFinishDate1;
    var fpickFinishDate2;

    function setDatePicker() {
      let tmpSrcFromDate = flatpickr("#srcFromDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcToDate = flatpickr("#srcToDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});

      fpickStartDate1 = flatpickr("#scr_ReservationsList_StartDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickStartDate2 = flatpickr("#scr_ReservationsList_StartDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });

      fpickFinishDate1 = flatpickr("#scr_ReservationsList_FinishDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickFinishDate2 = flatpickr("#scr_ReservationsList_FinishDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
    }

    function getProductTitle(prmEach) {

      if ((prmEach as any).ProductTitle!==null) {
        let objProductTitle = JSON.parse((prmEach as any).ProductTitle);
        if ((objProductTitle as any).HOTEL!==undefined) {
          return (objProductTitle as any).HOTEL[0];
        }
      }

      return "";
    }

    function getProductCounts(prmEach, prmType) {
      if ((prmEach as any).ProductCounts!==null) {
        let objParsed = JSON.parse((prmEach as any).ProductCounts);

        if (prmType==="FLIGHT" && (objParsed as any).FLIGHT!==undefined) {
          return Number((objParsed as any).FLIGHT);
        }
        if (prmType==="HOTEL" && (objParsed as any).HOTEL!==undefined) {
          return Number((objParsed as any).HOTEL);
        }
        if (prmType==="TRANSFER" && (objParsed as any).TRANSFER!==undefined) {
          return Number((objParsed as any).TRANSFER);
        }
      }

      return "";
    }

    const click_Search = () => {

      if (!submitButtonRef.value) {
        return;
      }

      let myAccount = document.getElementById("srcAccounts") as HTMLInputElement;
      arrReservationProductConfirmList.value = [];
      arrHotelList.value = [];
      const payload1 = {
        ProcessDateStart: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
        ProcessDateFinish: srcItem.value.srcToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcToDate) : "",
        StartDate1: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate1, 0),
        StartDate2: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate2, 0),
        FinishDate1: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate1, 0),
        FinishDate2: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate2, 0),
        VoucherNo: srcItem.value.srcVoucherNo,
        AccountVoucherNo: srcItem.value.srcAccountVoucherNo,
        Department: srcItem.value.srcDepartment,
        Market: srcItem.value.srcMarket,
        AccountID: myAccount.value ? filterAccounts(myAccount.value)[0].ID : "",
        PNR: srcItem.value.srcPNR,
        PaxName: srcItem.value.srcName,
        PaxLastName: srcItem.value.srcLastName,
        is_active: "active",
        ProductType: "HOTEL"
      };
      store.dispatch(Actions.RESERVATION_PRODUCTS_CONFIRM_LIST,  payload1).then((prmData) => {
        fillHotelList(prmData.data);
        arrReservationProductConfirmList.value = prmData.data;
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      }).catch((prmData) => {
        libTools.method.swalError("Tamam", "Hata Oluştu");
      });
      submitButtonRef.value.disabled = false;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
    };

    function checkAllReservations(prmHotelID) {
      const vvclass = document.getElementsByClassName("inputHotelProductConfirm" + prmHotelID);
      const checkboxid = document.getElementById("checkAll_" + prmHotelID) as HTMLInputElement;

      for (let i=0; i<vvclass.length; i++) {
        let eachElement = vvclass[i] as HTMLInputElement;
        if(checkboxid.checked){
          (eachElement.checked = true);
        }else {
          (eachElement.checked = false);
        }
      }

    }

    return {
      dateConvert,
      commonf,
      libTools,
      myParams,
      MasterConfig,
      srcItem,
      myAccountList,
      myAccountsTitle,
      getAccounts,
      click_Search,
      myDepartments,
      myMarkets,
      getProductTitle,
      getProductCounts,
      arrHotelList,
      arrReservationProductConfirmList,
      getReservationProductsByHotelID,
      reservationConfirmTypes,
      refHotelVoucherModal,
      click_Open_VoucherHotel,
      clickConfirm,
      checkAllReservations,
      submitButtonRef,
      showVoucherEmail,
      hideVoucherEmail,
      voucherItem,
      getCountByStatus,
      manuelVocuherConfirm,
    }
  },
});
